<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])">

    <CSidebarBrand class="d-md-down-none" to="/">
      <span class="c-sidebar-brand-full"><b>Sion Academy</b></span>
      <!--<img src="vbvmi-big.png" class="c-sidebar-brand-full"/>-->
      <img src="sa-logo-small.png" class="c-sidebar-brand-minimized"/>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

const navUser = [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Bahan PA',
        to: '/discipleship/bahan-pa',
        icon: 'cil-star'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Sion Raya',
        to: '/fellowship/sion-raya',
        icon: 'cil-cursor'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Referensi',
        to: '/resources/link',
        icon: 'cil-calculator'
      },
    ]
  }
]

const navTeamLeader = [
  {
    "_name": "CSidebarNav",
    "_children": [
      {
        _name: 'CSidebarNavItem',
        name: 'Bahan PA',
        to: '/discipleship/bahan-pa',
        icon: 'cil-star'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Sion Raya',
        to: '/fellowship/sion-raya',
        icon: 'cil-cursor'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Referensi',
        to: '/resources/link',
        icon: 'cil-calculator'
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Pembimbing",
        route: "/leader",
        icon: "cil-puzzle",
        items: [
          {
            name: "Jurnal PA",
            to: "/leader/jurnal-pa"
          },
          {
            name: "Update Sioner",
            to: "/leader/update-sioner"
          },
        ]
      },
    ]
  }
]

export default {
  name: 'TheSidebar',
  nav,

  async created(){
    var user = JSON.parse(localStorage.getItem('user'))

    if(user.access === 'ADMIN') 
      this.nav = this.$options.nav
    else if(user.access === 'LEADER')
      this.nav = navTeamLeader
    else
      this.nav = navUser
  },

  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
