export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Bahan PA',
        to: '/discipleship/bahan-pa',
        icon: 'cil-star'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Sion Raya',
        to: '/fellowship/sion-raya',
        icon: 'cil-cursor'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Referensi',
        to: '/resources/link',
        icon: 'cil-calculator'
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Pembimbing",
        route: "/leader",
        icon: "cil-puzzle",
        items: [
          {
            name: "Jurnal PA",
            to: "/leader/jurnal-pa"
          },
          {
            name: "Update Sioner",
            to: "/leader/update-sioner"
          },
        ]
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Admin",
        icon: "cil-puzzle",
        items: [
          {
            name: "Download",
            to: "/admin/download"
          },
          {
            name: "Atur Jadwal Sion Raya",
            to: "/leader/jadwal-sion-raya"
          },
          {
            name: "Atur Bahan PA",
            to: "/admin/atur-bahan-pa"
          },
          {
            name: "Atur Link",
            to: "/admin/manage-link"
          },
          {
            name: "Atur Kota",
            to: "/admin/manage-city"
          },
          {
            name: "Atur Periode",
            to: "/admin/manage-period"
          },
        ]
      },    
    ]
  }
]