<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img :src="pictureUrl" class="c-avatar-img "/>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="confirmLogout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
      pictureUrl: ''
    }
  },

  async mounted(){
    var user = JSON.parse(localStorage.getItem('user'))
    this.pictureUrl = user.pictureUrl
  },

  methods: {
    confirmLogout () {
      this.$confirm("Are you sure to logout?").then(() => {
        this.logout()
      });
    },

    logout() {
      localStorage.clear()
      this.$router.replace('/login', () => {})
    },
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>