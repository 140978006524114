<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://sion-academy.org" target="_blank">Sion Academy</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} Yayasan Semai</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">{{userAccess}}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
  data () {
    return {
      user: '',
      userAccess: ''
    }
  },

  async created () {
    this.user = JSON.parse(localStorage.getItem('user'))
    if(this.user.access==="ADMIN") this.userAccess = "PEMBINA"
    else if(this.user.access==="LEADER") this.userAccess = "PEMBIMBING"
    else this.userAccess = "USER"
  },

}
</script>
